<template>
  <v-container fluid class="service-detail-height overflow-auto">
    <v-row>
      <v-col cols="6" class="p-1">
        <v-card flat class="custom-grey-border remove-border-radius mb-4">
          <v-card-title class="headline grey lighten-4">
            <span
              class="font-weight-700 custom-headline color-custom-blue font-size-16"
              >Basic Information</span
            >
          </v-card-title>
          <v-card-text class="p-6 font-size-16">
            <table width="100%">
              <tr>
                <td class="font-size-18 font-weight-500 py-1">Series</td>
                <td class="font-size-18 py-1">
                  <span v-if="detail && detail.item_number">
                    {{ detail.item_number }}
                  </span>
                  <em v-else class="text--secondary"> no Series </em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Model type
                </td>
                <td class="font-size-18 py-2">
                  <span v-if="detail && detail.model_type">{{
                    detail.model_type
                  }}</span>
                  <em v-else class="text--secondary"> no model type </em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1">Model Code 1</td>
                <td class="font-size-18 py-1">
                  <span v-if="detail && detail.model">{{ detail.model }}</span>
                  <em v-else class="text--secondary"> no model code 1</em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1">Model Code 2</td>
                <td class="font-size-18 py-1">
                  <span v-if="detail && detail.model_no">{{
                    detail.model_no
                  }}</span>
                  <em v-else class="text--secondary"> no model code 2 </em>
                </td>
              </tr>

              <tr>
                <td class="font-size-18 font-weight-500 py-1">Class</td>
                <td class="font-size-18 py-1">
                  <template v-if="dataLoading">
                    <v-skeleton-loader
                      class="custom-skeleton width-80"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <template v-if="detail.website_url">
                      {{ detail.website_url }}
                    </template>
                    <em v-else class="text--secondary"> no class</em>
                  </template>
                </td>
              </tr>

              <tr>
                <td class="font-size-18 font-weight-500 py-1">
                  Mast Height (mm)
                </td>
                <td class="font-size-18 py-1">
                  <span v-if="detail && detail.mast_height">
                    {{ detail.mast_height }}
                  </span>
                  <em v-else class="text--secondary"> no Mast Height (mm)</em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1">Tires</td>
                <td class="font-size-18 py-1">
                  <span v-if="detail && detail.tires">
                    {{ detail.tires }}
                  </span>
                  <em v-else class="text--secondary"> no Tires</em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1">ATT</td>
                <td class="font-size-18 py-1">
                  <span v-if="detail && detail.att">
                    {{ detail.att }}
                  </span>
                  <em v-else class="text--secondary"> no ATT</em>
                </td>
              </tr>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" class="p-1" v-if="false">
        <v-card flat class="custom-grey-border remove-border-radius mb-4">
          <v-card-title class="headline grey lighten-4">
            <span
              class="font-weight-700 custom-headline color-custom-blue font-size-16"
              >Quantity Information</span
            >
          </v-card-title>
          <v-card-text class="p-6 font-size-16">
            <table width="100%">
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="tooltip-border-dashed"
                        >Available Quantity</span
                      >
                    </template>
                    <span
                      >The available quantity for sale at<br />the beginning of
                      the accounting period.</span
                    >
                  </v-tooltip>
                </td>
                <td class="font-size-18 py-1">
                  <span v-if="detail && detail.available_stock">
                    {{ detail.available_stock }}
                  </span>
                  <em v-else class="text--secondary">
                    no Available Quantity.
                  </em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  UOM
                </td>
                <td class="font-size-18 py-1">
                  <span v-if="detail && detail.unit">
                    {{ detail.unit }}
                  </span>
                  <em v-else class="text--secondary"> no UOM</em>
                </td>
              </tr>
            </table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" class="p-1">
        <v-card flat class="custom-grey-border remove-border-radius mb-4">
          <v-card-title class="headline grey lighten-4">
            <span
              class="font-weight-700 custom-headline color-custom-blue font-size-16"
              >Product Extra Information</span
            >
          </v-card-title>
          <v-card-text class="p-6 font-size-16">
            <v-row>
              <v-col cols="12" class="p-1">
                <table width="100%">
                  <tr>
                    <td class="font-size-18 font-weight-500 py-1" width="150">
                      Engine
                    </td>
                    <td class="font-size-18 py-1">
                      <span v-if="detail && detail.engine">
                        {{ detail.engine }}
                      </span>
                      <em v-else class="text--secondary"> no Engine</em>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-18 font-weight-500 py-1">
                      Power Source
                    </td>
                    <td class="font-size-18 py-1">
                      <span v-if="detail && detail.power_source">
                        {{ detail.power_source }}
                      </span>
                      <em v-else class="text--secondary"> no Power Source</em>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-18 font-weight-500 py-1" width="150">
                      Battery voltage (V)
                    </td>
                    <td class="font-size-18 py-1">
                      <span v-if="detail && detail.battery_voltage">
                        {{ detail.battery_voltage }}
                      </span>
                      <em v-else class="text--secondary">
                        no Battery voltage (V)</em
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-18 font-weight-500 py-1" width="150">
                      Battery Capacity (Ah)
                    </td>
                    <td class="font-size-18 py-1">
                      <span v-if="detail && detail.battery_capacity">
                        {{ detail.battery_capacity }}
                      </span>
                      <em v-else class="text--secondary">
                        no Battery Capacity (Ah)</em
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-18 font-weight-500 py-1" width="150">
                      Delivery Terms (days)
                    </td>
                    <td class="font-size-18 py-1">
                      <span v-if="detail && detail.delivery_terms">
                        {{ detail.delivery_terms }}
                      </span>
                      <em v-else class="text--secondary">
                        no Delivery Terms (days)</em
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-18 font-weight-500 py-1" width="150">
                      Factory
                    </td>
                    <td class="font-size-18 py-1">
                      <span v-if="detail && detail.factory">
                        {{ detail.factory }}
                      </span>
                      <em v-else class="text--secondary"> no Factory</em>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-18 font-weight-500 py-1" width="150">
                      Warranty (truck)
                    </td>
                    <td class="font-size-18 py-1">
                      <span v-if="detail && detail.warranty_truck">
                        {{ detail.warranty_truck }}
                      </span>
                      <em v-else class="text--secondary">
                        no Warranty (truck)</em
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-18 font-weight-500 py-1" width="150">
                      Warranty (li-ion battery)
                    </td>
                    <td class="font-size-18 py-1">
                      <span v-if="detail && detail.warranty_li_ion_battery">
                        {{ detail.warranty_li_ion_battery }}
                      </span>
                      <em v-else class="text--secondary">
                        no Warranty (li-ion battery)</em
                      >
                    </td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" class="p-1">
        <v-card flat class="custom-grey-border remove-border-radius mb-4">
          <v-card-title class="headline grey lighten-4">
            <span
              class="font-weight-700 custom-headline color-custom-blue font-size-16"
              >Specs Information</span
            >
          </v-card-title>
          <v-card-text class="p-6 font-size-16">
            <v-row>
              <v-col md-4>
                <table width="100%">
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Length to Fork Face (mm)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.length_to_fork">{{
                        detail.length_to_fork
                      }}</span>
                      <em v-else class="text--secondary">
                        no length of fork
                      </em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Overall Width (mm)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.overall_width">{{
                        detail.overall_width
                      }}</span>
                      <em v-else class="text--secondary"> no overall width</em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Turning radius (mm)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.turning_radius">{{
                        detail.turning_radius
                      }}</span>
                      <em v-else class="text--secondary">
                        no turning radius
                      </em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Ground Clearance [wheelbase center] (mm)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.ground_clearance">{{
                        detail.ground_clearance
                      }}</span>
                      <em v-else class="text--secondary">
                        no ground clearance
                      </em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Travel Speed [lad/unlad] (mm)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.travel_speed">{{
                        detail.travel_speed
                      }}</span>
                      <em v-else class="text--secondary"> no travel speed </em>
                    </td>
                  </tr>
                </table>
              </v-col>

              <v-col md-4>
                <table width="100%">
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Lift Speed [lad/unlad] (mm)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.lift_speed">{{
                        detail.lift_speed
                      }}</span>
                      <em v-else class="text--secondary"> no lift speed</em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Max gradeability [lad/unlad] (mm)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.max_grade">{{
                        detail.max_grade
                      }}</span>
                      <em v-else class="text--secondary"> no max grade</em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Drive Motor (kW)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.drive_medium">{{
                        detail.drive_medium
                      }}</span>
                      <em v-else class="text--secondary"> no drive medium</em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Hydraulic Motor (kW)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.hydraulic">{{
                        detail.hydraulic
                      }}</span>
                      <em v-else class="text--secondary"> no hydraulic</em>
                    </td>
                  </tr>
                </table>
              </v-col>

              <v-col md-4>
                <table width="100%">
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Power (kW/rpm)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.power">{{
                        detail.power
                      }}</span>
                      <em v-else class="text--secondary"> no power</em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Torque (Nm/rpm)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.torque">{{
                        detail.torque
                      }}</span>
                      <em v-else class="text--secondary"> no torque</em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Noise level (dB)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.noise_level">{{
                        detail.noise_level
                      }}</span>
                      <em v-else class="text--secondary"> no noise level</em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Entry Date
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.entry_date">{{
                        detail.entry_date
                      }}</span>
                      <em v-else class="text--secondary"> no entry date</em>
                    </td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" class="p-1">
        <v-card flat class="custom-grey-border remove-border-radius mb-4">
          <v-card-title class="headline grey lighten-4">
            <span
              class="font-weight-700 custom-headline color-custom-blue font-size-16"
              >Pricing Information</span
            >
          </v-card-title>
          <v-card-text class="p-6 font-size-16">
            <v-row>
              <v-col md-4>
                <table width="100%">
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Local Currency
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.local_currency">{{
                        detail.local_currency
                      }}</span>
                      <em v-else class="text--secondary">
                        no local currency
                      </em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Local Selling Price
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.local_selling_price">{{
                        detail.local_selling_price
                      }}</span>
                      <em v-else class="text--secondary">
                        no local selling price</em
                      >
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Local Selling Price (FX)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.local_selling_price_fx">{{
                        detail.local_selling_price_fx
                      }}</span>
                      <em v-else class="text--secondary">
                        no local selling price (FX)
                      </em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Local Selling Price (JPY)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.local_selling_price_jpy">{{
                        detail.local_selling_price_jpy
                      }}</span>
                      <em v-else class="text--secondary">
                        no local selling price (JPY)
                      </em>
                    </td>
                  </tr>
                </table>
              </v-col>

              <v-col md-4>
                <table width="100%">
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Import/DN Currency
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.import_dn_currency">{{
                        detail.import_dn_currency
                      }}</span>
                      <em v-else class="text--secondary">
                        no import/dn currency</em
                      >
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Import/DN Price
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.import_dn_price">{{
                        detail.import_dn_price
                      }}</span>
                      <em v-else class="text--secondary">
                        no import/dn price</em
                      >
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Import/DN Price (FX)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.import_dn_price_fx">{{
                        detail.import_dn_price_fx
                      }}</span>
                      <em v-else class="text--secondary">
                        no import dn price(fx)</em
                      >
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      class="font-size-18 font-weight-500 py-1"
                      width="150"
                    >
                      Import/DN Price (JPY)
                    </td>
                    <td class="font-size-18 py-1 pre-wrap">
                      <span v-if="detail && detail.import_dn_price_jpy">{{
                        detail.import_dn_price_jpy
                      }}</span>
                      <em v-else class="text--secondary">
                        no import dn price(JPY)</em
                      >
                    </td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" class="p-1" v-if="false">
        <v-card flat class="custom-grey-border remove-border-radius mb-4">
          <v-card-title class="headline grey lighten-4">
            <span
              class="font-weight-700 custom-headline color-custom-blue font-size-16"
              >Description</span
            >
          </v-card-title>
          <v-card-text class="p-6 font-size-16">
            <table width="100%">
              <tr>
                <td
                  valign="top"
                  class="font-size-18 font-weight-500 py-1"
                  width="150"
                >
                  Description
                </td>
                <td class="font-size-18 py-1 pre-wrap">
                  <span v-if="detail && detail.description">{{
                    detail.description
                  }}</span>
                  <em v-else class="text--secondary"> no description </em>
                </td>
              </tr>
            </table>
          </v-card-text>
        </v-card>
      </v-col>

      <CustomFormDetail class="px-0" module="product" :module-id="detail.id" />
    </v-row>
    <v-row v-if="false">
      <v-col cols="6" class="pb-0 pl-4">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="position: relative"
        >
          <table class="width-100">
            <tr>
              <th colspan="2" class="font-size-18 py-2">Basic Information</th>
            </tr>
            <!--       <pre>{{ detail }}</pre> -->
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Name
              </td>
              <td class="font-size-18 py-2">
                <span v-if="detail && detail.name">{{ detail.name }}</span>
                <em v-else class="text--secondary"> no name </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Item Number
              </td>
              <td class="font-size-18 py-2">
                <span v-if="detail && detail.barcode">{{
                  detail.barcode
                }}</span>
                <em v-else class="text--secondary"> no item number </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Part Number
              </td>
              <td class="font-size-18 py-2">
                <span v-if="detail && detail.part_number">{{
                  detail.part_number
                }}</span>
                <em v-else class="text--secondary"> no part number </em>
              </td>
            </tr>

            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Brand
              </td>
              <td class="font-size-18 py-2">
                <span v-if="detail && detail.brand">{{ detail.brand }}</span>
                <em v-else class="text--secondary"> no brand </em>
              </td>
            </tr>

            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Model
              </td>
              <td class="font-size-18 py-2">
                <span v-if="detail && detail.model">{{ detail.model }}</span>
                <em v-else class="text--secondary"> no model </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Model no
              </td>
              <td class="font-size-18 py-2">
                <span v-if="detail && detail.model_no">{{
                  detail.model_no
                }}</span>
                <em v-else class="text--secondary"> no model no </em>
              </td>
            </tr>

            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Category
              </td>
              <td class="font-size-18 py-2">
                <span v-if="detail && detail.category">{{
                  detail.category
                }}</span>
                <em v-else class="text--secondary"> no category </em>
              </td>
            </tr>

            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Sellable
              </td>
              <td class="font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ detail.is_sellable ? "Yes" : "No" }}
                </template>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Website URL
              </td>
              <td class="font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="detail.website_url">
                    {{ detail.website_url }}
                  </template>
                  <em v-else class="text--secondary"> no website url</em>
                </template>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Purchase Date
              </td>
              <td class="font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="detail.purchase_date">
                    {{ formatDate(detail.purchase_date) }}
                  </template>
                  <em v-else class="text--secondary"> no purchase date</em>
                </template>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2 d-flex" width="250">
                Description
              </td>
              <td class="font-size-18 py-2">
                <span v-if="detail && detail.description">{{
                  detail.description
                }}</span>
                <em v-else class="text--secondary"> no description </em>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Manage Stock
              </td>
              <td class="font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ detail.manage_stock ? "Yes" : "No" }}
                </template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Track Batch
              </td>
              <td class="font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ detail.track_batch ? "Yes" : "No" }}
                </template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Tax Preference
              </td>
              <td class="font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ detail.is_taxable ? "Taxable" : "Non-Taxable" }}
                </template>
              </td>
            </tr>
            <tr v-if="false && detail.is_taxable">
              <td class="font-size-18 font-weight-500 py-2" width="250">Tax</td>
              <td class="font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="detail.tax"
                    >{{ detail.tax.name }} [{{
                      detail.tax.percentage
                    }}%]</template
                  >
                  <template v-else
                    ><em class="text--secondary">No Tax</em></template
                  >
                </template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Tax Exemption
              </td>
              <td class="font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="detail.tax_exemption"
                    >{{ detail.tax_exemption.name }} [{{
                      detail.tax_exemption.reason
                    }}]</template
                  >
                  <template v-else
                    ><em class="text--secondary">No Tax Exemption</em></template
                  >
                </template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Commission Rate (%)
              </td>
              <td class="font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else> {{ detail.commission_rate }}</template>
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
      <v-col cols="6" class="pb-0 gray-background pl-4">
        <perfect-scrollbar
          ref="sidebarScrollbar"
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <table class="width-100">
            <tr v-if="false">
              <th colspan="2" class="font-size-18 py-2">Pricing Information</th>
            </tr>

            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="200">
                Selling Price/Unit Price
              </td>
              <td class="font-size-18 py-2">
                <span class="text--uppercase pr-2 font-weight-700">{{
                  formatMoney(detail.sale_price)
                }}</span>
              </td>
            </tr>

            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="200">
                Discounted price
              </td>
              <td class="font-size-18 py-2">
                <span class="text--uppercase pr-2">{{
                  formatMoney(detail.discount_price)
                }}</span>
              </td>
            </tr>
            <br />
            <br />

            <tr>
              <th colspan="2" class="font-size-18 py-2">
                Quantity Information
              </th>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <template>
                  <span class="tooltip-border-dashed">Initial stock</span>
                </template>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <span class="font-weight-700">{{
                    detail.initial_stock
                  }}</span></template
                >
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Available Quantity</span
                    >
                  </template>
                  <span
                    >The available quantity for sale at<br />the beginning of
                    the accounting period.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <span class="font-weight-700">{{
                    detail.available_stock
                  }}</span></template
                >
              </td>
            </tr>
            <tr>
              <template v-if="detail.unit">
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  UOM
                </td>
                <td class="font-size-18 py-2">
                  <span v-if="detail && detail.unit"> {{ detail.unit }}</span>
                  <em v-else class="text--secondary"> no UOM</em>
                </td>
              </template>
            </tr>
            <br />
            <br />
            <tr>
              <th colspan="2" class="font-size-18 py-2">
                Warranty Information
              </th>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="200">
                Duration
              </td>
              <td class="font-size-18 py-2">
                <span v-if="detail.warranty_check && detail.duration_time"
                  >{{ detail.warranty_check }}
                  <span class="text-capitalize"
                    >{{ detail.duration_time }}
                    <template v-if="detail.warranty_check != 1">(s)</template>
                  </span>
                </span>
                <em v-else class="text-muted">no warranty</em>
              </td>
            </tr>

            <tr
              v-if="false"
              :class="{
                'custom-border-top':
                  lodash.isEmpty(detail.warranty_data) === false,
              }"
            >
              <th colspan="2" class="font-size-18 py-2">Accounting Stock</th>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Re-Order Level</span
                    >
                  </template>
                  <span
                    >When the stock reaches the reorder level, <br />a
                    notification will be sent to you.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.reorder_level)
                }}</template>
              </td>
            </tr>

            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Stock on Hand</span
                    >
                  </template>
                  <span>Current stock available for this item.</span>
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.stock_on_hand)
                }}</template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Committed Stock</span
                    >
                  </template>
                  <span
                    >Stock that is committed to job(s) but not yet
                    invoiced.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.committed_stock)
                }}</template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Available for Sale</span
                    >
                  </template>
                  <span
                    >Available for sale = Stock on Hand - Committed Stock.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.available_stock)
                }}</template>
              </td>
            </tr>
          </table>

          <div
            v-if="false"
            class="mt-4"
            :class="{
              'custom-border-top':
                lodash.isEmpty(detail.warranty_data) === false,
            }"
          >
            <v-row>
              <v-col class="pt-0" md="8">
                <p class="font-size-18 font-weight-700 py-2 pl-1 my-0">
                  Product Summary
                </p>
              </v-col>
              <v-col class="pt-0" md="4">
                <v-select
                  :items="summaryFilter"
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-model="summary"
                  hide-details
                  color="cyan"
                  item-color="cyan"
                  class="pt-0 mt-0 custom-appex-filter"
                  item-text="text"
                  item-value="value"
                  v-on:change="getSummary(true)"
                >
                </v-select>
              </v-col>
              <v-col md="6" offset-md="3" v-if="dataLoading">
                <v-progress-linear
                  color="cyan"
                  class="my-20"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
              <v-col md="12" v-else class="text-center">
                <template v-if="series.length > 0">
                  <apexchart
                    type="bar"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </template>
                <template v-else
                  ><em class="text--secondary font-size-16"
                    >Nothing Found...</em
                  ></template
                >
              </v-col>
            </v-row>
          </div>
        </perfect-scrollbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
import CustomFormDetail from "@/view/pages/custom-field-v2/CustomFormDetail.vue";
export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      product: 0,
      panel: 0,
      panelImage: 0,
      dataLoading: true,
      summary: "this_week",
      summaryFilter: [
        { text: "This Week", value: "this_week" },
        { text: "Previous Week", value: "previous_week" },
        { text: "This Month", value: "this_month" },
        { text: "Previous Month", value: "previous_month" },
        /*{ text: "This Quarter", value: "this_quarter" },
        { text: "Previous Quarter", value: "previous_quarter" },*/
        { text: "This Year", value: "this_year" },
        { text: "Previous Year", value: "previous_year" },
      ],
      chartOptions: {
        chart: {
          id: "product-summary",
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif",
          },
        },
      },
      series: [],
      tOneFields: [
        {
          title: "Name",
          key: "name",
          child: false,
        },
        /*{
          title: "Reference",
          key: "reference",
          child: false
        },*/
        {
          title: "Item Code",
          key: "barcode",
          child: false,
        },
        /*{
          title: "SKU",
          key: "sku",
          child: false
        },
        {
          title: "UPC",
          key: "upc",
          child: false
        },
        {
          title: "EAN",
          key: "ean",
          child: false
        },
        {
          title: "ISBN",
          key: "isbn",
          child: false
        },*/
        {
          title: "Part Number",
          key: "part_number",
          child: false,
        },
        /*  {
          title: "Serial Number",
          key: "serial_number",
          child: false,
        }, */
        /*{
          title: "HSN Code",
          key: "hsn_code",
          child: false
        },*/
        {
          title: "Description",
          key: "description",
          child: false,
        },
        /*{
          title: "Supplier",
          key: "supplier",
          child: "display_name",
        },*/
        {
          title: "Brand",
          key: "brand",
          child: "text",
        },
        /* {
          title: "Manufacturer",
          key: "manufacturer",
          child: "text",
        }, */
        {
          title: "Category",
          key: "category",
          child: "text",
        },
        /* {
          title: "UOM",
          key: "uom",
          child: "text",
        }, */
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.product = this.lodash.toSafeInteger(param.id);
        this.getSummary(false);
      },
    },
  },
  components: {
    CustomFormDetail,
  },
  methods: {
    getSummary(scrollBottom) {
      const _this = this;
      if (_this.product > 0) {
        _this.dataLoading = true;
        _this.series = [];
        _this.$store
          .dispatch(QUERY, {
            url: "product/" + _this.product + "/summary",
            data: {
              filter: _this.summary,
            },
          })
          .then(({ data }) => {
            let categories = Object.values(data.categories);
            if (
              _this.lodash.isEmpty(categories) === false &&
              _this.lodash.isArray(categories)
            ) {
              _this.chartOptions = {
                ..._this.chartOptions,
                ...{
                  xaxis: {
                    categories: categories.map((row) => {
                      if (
                        _this.summary == "this_year" ||
                        _this.summary == "previous_year"
                      ) {
                        return moment(row).format("MMMM, YYYY");
                      }
                      return moment(row).format("Do, MMMM, YYYY");
                    }),
                  },
                },
              };

              if (_this.lodash.isEmpty(data.series) === false) {
                if (
                  _this.lodash.isEmpty(data.series.quotation_counted) === false
                ) {
                  _this.series.push({
                    name: "Quotation",
                    data: Object.values(data.series.quotation_counted),
                  });
                }
                if (
                  _this.lodash.isEmpty(data.series.invoice_counted) === false
                ) {
                  _this.series.push({
                    name: "Invoice",
                    data: Object.values(data.series.invoice_counted),
                  });
                }
                if (_this.lodash.isEmpty(data.series.job_counted) === false) {
                  _this.series.push({
                    name: "Job",
                    data: Object.values(data.series.job_counted),
                  });
                }
              }
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
            if (_this.lodash.isEmpty(_this.series) === false && scrollBottom) {
              let objDiv = _this.$refs["sidebarScrollbar"].$el;
              _this.$nextTick(() => {
                objDiv.scrollTop = objDiv.scrollHeight;
              });
            }
          });
      }
    },
  },
  mounted() {
    this.getSummary();
  },
};
</script>
<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}
</style>
