<template>
  <div>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll"
      style="max-height: 90vh; position: relative"
    >
      <v-row class="justify-center">
        <template>
          <div>
            <v-avatar size="120">
              <v-img
                width="250"
                :src="selectedImage ? selectedImage : $defaultImage"
                :lazy-src="$defaultImage"
                aspect-ratio="1"
                class="grey lighten-2 position-relative"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </div>
        </template>
      </v-row>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { PATCH, DELETE } from "@/core/services/store/request.module";
export default {
  props: {
    type: {
      type: String,
      required: true,
      default: "product",
    },
    parent: {
      type: Number,
      required: true,
      default: 0,
    },
    isPageLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    images: {
      type: Array,
      required: true,
      default: () => {
        return new Array();
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      primaryLoading: false,
      deleteLoading: false,
      isPrimary: false,
      selectedImage: null,
      selectedImageId: 0,
      itemImages: new Array(),
    };
  },
  watch: {
    images: {
      deep: true,
      immediate: true,
      handler(param) {
        this.itemImages = param;
        this.setFirstImage();
      },
    },
  },
  mounted() {
    this.setFirstImage();
  },
  methods: {
    deleteImage() {
      const _this = this;
      _this.pageLoading = true;
      _this.deleteLoading = true;

      _this.$store
        .dispatch(DELETE, {
          url: `file-manager/delete/${_this.selectedImageId}`,
        })
        .then(() => {
          let index = _this.lodash.findIndex(_this.itemImages, function (row) {
            return row.id == _this.selectedImageId;
          });
          if (index >= 0) {
            _this.itemImages.splice(index, 1);
            //console.log('removed');
            //issue fixed
            if (_this.itemImages.length <= 0) {
              _this.images = [];
            }
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.deleteLoading = false;
        });
    },
    setAsPrimary() {
      const _this = this;
      _this.pageLoading = true;
      _this.primaryLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: `${_this.type}/${_this.parent}/image/${_this.selectedImageId}/primary`,
        })
        .then(({ data }) => {
          _this.itemImages = data;
          _this.setFirstImage();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.primaryLoading = false;
        });
    },
    setFirstImage() {
      const _this = this;
      let firstImage = _this.lodash.head(_this.itemImages);
      if (_this.lodash.isEmpty(firstImage) === false) {
        _this.selectedImage = firstImage.file.url;
        _this.selectedImageId = firstImage.id;
        _this.isPrimary = firstImage.primary;
      }
    },
    setImage(row) {
      this.selectedImage = row.file.url;
      this.selectedImageId = row.id;
      this.isPrimary = row.primary;
    },
  },
};
</script>
